import Basket from './basket'
import BasketTotal, { IBasketTotals } from './basketTotal'
import User from './user'
import { IProduct } from './eCapi'
import Journey, { IJourneyPage } from './journey'
import SalesForceAudience from './salesForceAudience'
import ExperienceStore from './store'
import {
  IUnknownObject,
  CustomerDetails,
  Conditions,
  ActiveExperiments,
  ParamExperience
} from './types' 
import UrlParameters from './urlParameters'
import DateRange from './dateRange'
import StandingOrders, { IStandingOrder } from './standingOrders'
import XpMismatches, { XPEvent, casperMismatchEvent } from './xpMismatches'
import SalesForceAudiencePerso from './salesforceAudiencePerso'
import { isObject } from './predicates'
import { debugMode } from './debugMode'
import Subscriptions, { MergedSubscriptions } from './subscriptions'
import Once from './once'

export default class Experience {
  public store: ExperienceStore
  public journey?: Promise<Journey> // visits // includes since?
  public user?: Promise<User> // visitorIs - 
  public basketTotal?: Promise<BasketTotal> // basketTotalIs
  public basket?: Promise<Basket> // basketContains
  public salesForceAudience?: Promise<SalesForceAudience> // salesForceAudienceIs
  public salesForceAudiencePerso?: Promise<SalesForceAudiencePerso>
  public urlParameters?: Promise<UrlParameters> // urlParameterIs
  public dateRange?: Promise<DateRange> // isInDateRange
  public standingOrders?: Promise<StandingOrders> // standingOrdersContains
  public subscriptions?: Promise<Subscriptions> // subscriptionsContains
  public xpMistmatches?: Promise<XpMismatches> // mismatches
  public onceRule?: Promise<Once> // once
  private currentEvalReject: (() => void) | null = null
  private hasSimpleUserPredicate = false
  public currentRule: string[] = [] 

  constructor(name: string, param?: ParamExperience) {
    this.store = new ExperienceStore(name, param)
  }
  private async doEval(
    eventData: ITargetingRulesEvaluationResult
  ): Promise<ITargetingRulesEvaluationResult> {
    return new Promise(async (resolve, reject) => {
      this.currentEvalReject = reject.bind(Promise)
      const tests = []
      if (this.user) {
        const userRule = await this.user
        if (userRule.hasPredicates) {
          const { isValid, user } = await userRule.getState()
          this.logDebug('visitorIs - isValid : ' + isValid.toString())
          this.currentRule.push('visitorIs')
          tests.push(isValid)
          eventData.user = user
        }
      }
      if (this.basketTotal) {
        const basketTotal = await this.basketTotal
        if (basketTotal.hasPredicates) {
          const { isValid, totals } = await basketTotal.getState()
          this.logDebug('basketTotalIs - isValid : ' + isValid.toString())
          this.currentRule.push('basketTotalIs')
          tests.push(isValid)
          eventData.totals = totals
        }
      }
      if (this.basket) {
        const basket = await this.basket
        if (basket.hasPredicates) {
          const { isValid, products } = await basket.getState()
          this.logDebug('basketContains - isValid : ' + isValid.toString())
          this.currentRule.push('basketContains')
          tests.push(isValid)
          eventData.products = products
        }
      }
      if (this.journey) {
        const journey = await this.journey
        if (journey.hasPredicates) {
          const { isValid, pages } = journey.getState()
          this.logDebug('visits/since - isValid : ' + isValid.toString())
          this.currentRule.push('visits')
          tests.push(isValid)
          eventData.pages = pages
        }
      }
      if (this.salesForceAudience) {
        const salesForceAudience = this.salesForceAudience
        if (salesForceAudience.hasPredicates) {
          const { isValid } = await salesForceAudience.getState()
          this.logDebug(
            'salesForceAudienceIs - isValid : ' + isValid.toString()
          )
          this.currentRule.push('salesForceAudienceIs')
          tests.push(isValid)
        }
      }
      if (this.salesForceAudiencePerso) {
        const salesForceAudiencePerso = this.salesForceAudiencePerso
        if (salesForceAudiencePerso.hasPredicates) {
          const { isValid } = await salesForceAudiencePerso.getState()
          this.logDebug(
            'salesForceAudiencePersoIs - isValid : ' + isValid.toString()
          )
          this.currentRule.push('salesForceAudiencePersoIs')
          tests.push(isValid)
        }
      }
      if (this.urlParameters) {
        const urlParameters = await this.urlParameters
        if (urlParameters.hasPredicates) {
          const { isValid, urlParams } = urlParameters.getState()
          this.logDebug('urlParameterIs - isValid : ' + isValid.toString())
          this.currentRule.push('urlParameterIs')
          tests.push(isValid)
          eventData.urlParams = urlParams
        }
      }
      if (this.dateRange) {
        const dateRange = await this.dateRange
        if (dateRange.hasPredicates) {
          const { isValid, date } = dateRange.getState()
          this.logDebug('isInDateRange - isValid : ' + isValid.toString())
          this.currentRule.push('isInDateRange')
          tests.push(isValid)
          eventData.date = date
        }
      }
      if (this.standingOrders) {
        const standingOrdersInstance = await this.standingOrders
        if (standingOrdersInstance.hasPredicates) {
          const {
            isValid,
            standingOrders,
          } = await standingOrdersInstance.getState()
          this.logDebug(
            'standingOrdersContains - isValid : ' + isValid.toString()
          )
          this.currentRule.push('standingOrdersContains')
          tests.push(isValid)
          eventData.standingOrders = standingOrders
        }
      }
      if (this.subscriptions) {
        const subscriptionsInstance = await this.subscriptions
        if (subscriptionsInstance.hasPredicates) {
          const {
            isValid,
            subscriptions,
          } = await subscriptionsInstance.getState()
          this.logDebug(
            'subscriptionsContains - isValid : ' + isValid.toString()
          )
          this.currentRule.push('subscriptionsContains')
          tests.push(isValid)
          eventData.subscriptions = subscriptions
        }
      }
      if (this.xpMistmatches) {
        const xpMistmatchesInstance = await this.xpMistmatches
        if (xpMistmatchesInstance.hasPredicates) {
          const { isValid, xpName } = xpMistmatchesInstance.getState()
          this.logDebug('mismtaches - isValid : ' + isValid.toString())
          this.currentRule.push('xpMistmatches')
          tests.push(isValid)
          eventData.xpName = xpName
        }
      }
      if (this.onceRule) {
        const onceRuleInstance = await this.onceRule
        const { isValid, xpName } = onceRuleInstance.getState()
        this.logDebug('once - isValid : ' + isValid.toString())
        this.currentRule.push('once')
        tests.push(isValid)
        eventData.xpName = xpName
      }
      if (tests.length) {
        eventData.allValid = tests.every((test) => test)
      }
      this.currentEvalReject = null
      resolve(eventData)
    })
  }
  private isSimpleUserExperience(): boolean {
    return (
      this.hasSimpleUserPredicate &&
      !this.basketTotal &&
      !this.basket &&
      !this.journey && // includes since
      !this.salesForceAudience &&
      !this.salesForceAudiencePerso &&
      !this.urlParameters &&
      !this.dateRange &&
      !this.standingOrders &&
      !this.xpMistmatches
    )
  }
  public async evaluate(): Promise<ITargetingRulesEvaluationResult> {
    if (this.currentEvalReject !== null) {
      this.currentEvalReject()
    }
    const eventData: ITargetingRulesEvaluationResult = {
      unsubscribe: this.unsubscribe.bind(this),
    }
    try {
      this.logDebug('evaluate()')
      const resolvedEventData = await this.doEval(eventData)
      if (resolvedEventData.allValid) {
        /*
        * https://dsu-confluence.nestle.biz/x/vhcNDw && DIGEX-10165 && DIGEX-12220
        Page builder loader has it's own event. So, with this check duplicate event is not being triggered
        * exclude object push to gtm if personalisation is triggerred from Page builder
        */
        if (!this.store?.noTracking) {
          this.trackCasper('true')
        }
        this.matchCallback(resolvedEventData)
        this.store.setTriggered()
        this.logDebug(
          'doEval() allValid : ' + resolvedEventData.allValid.toString()
        )
      } else if (resolvedEventData.allValid !== undefined) {
        if (!this.store?.noTracking) {
          this.trackCasper('false')
        }
        this.mismatchCallback(resolvedEventData)
        this.store.setMismatched()
        this.dispatchXpMismatch()
        this.logDebug(
          'doEval() allValid : ' + resolvedEventData.allValid.toString()
        )
      }
    } catch {
      return eventData
    }

    return eventData
  }

  private trackCasper(match: string){
    window.gtmDataObject = window.gtmDataObject || []
    window.gtmDataObject.push({
      event: 'casper_experience',
      experience_name: this.store.name,
      experience_rule: this.currentRule.join(' | '),
      experience_match: match,
      event_raised_by: 'casper',
    })
  }

  private dispatchXpMismatch() {
    const casperEvent = document.createEvent('Event')
    casperEvent.initEvent(casperMismatchEvent + this.store.name, true, true)
    ;(casperEvent as XPEvent).xpName = this.store.name
    document.dispatchEvent(casperEvent)
  }

  public mismatches(...predicates: Conditions): this {
    this.xpMistmatches = import(
      /* webpackChunkName: "XpMismatches" */ './xpMismatches'
    ).then((XpMismatchesRule) => {
      const xpMistmatches = new XpMismatchesRule.default(
        (): Promise<ITargetingRulesEvaluationResult> => this.evaluate(),
        predicates
      )
      xpMistmatches.addPredicates(predicates)
      return xpMistmatches
    })
    return this
  }

  public standingOrdersContains(...orders: Conditions): this {
    this.standingOrders = import(
      /* webpackChunkName: "StandingOrders" */ './standingOrders'
    ).then((StandingOrdersRule) => {
      const standingOrders = new StandingOrdersRule.default()
      standingOrders.addPredicates(orders)
      return standingOrders
    })
    return this
  }

  public subscriptionsContains(...subscriptionsConditions: Conditions): this {
    this.subscriptions = import(
      /* webpackChunkName: "Subscriptions" */ './subscriptions'
    ).then((SubscriptionsRule) => {
      const subscriptions = new SubscriptionsRule.default()
      subscriptions.addPredicates(subscriptionsConditions)
      return subscriptions
    })
    return this
  }

  public isInDateRange(...dates: Conditions): this {
    this.dateRange = import(
      /* webpackChunkName: "DateRange" */ './dateRange'
    ).then((DateRangeRule) => {
      const dateRange = new DateRangeRule.default()
      dateRange.addPredicates(dates)
      return dateRange
    })
    return this
  }

  public urlParameterIs(...urlParams: Conditions): this {
    this.urlParameters = import(
      /* webpackChunkName: "UrlParameters" */ './urlParameters'
    ).then((UrlParametersRule) => {
      const urlParameters = new UrlParametersRule.default()
      urlParameters.addPredicates(urlParams)
      return urlParameters
    })
    return this
  }

  public salesForceAudienceIs(...segments: Conditions): this {
    this.salesForceAudience = new SalesForceAudience()
    this.salesForceAudience.addPredicates(segments)
    return this
  }

  public salesForceAudiencePersoIs(...segments: Conditions): this {
    this.salesForceAudiencePerso = new SalesForceAudiencePerso()
    this.salesForceAudiencePerso.addPredicates(segments)
    return this
  }
  
  public visitorIs(...predicates: Conditions): this {
    this.user = import(/* webpackChunkName: "User" */ './user').then(
      (UserRule) => {
        const user = new UserRule.default(
          (): Promise<ITargetingRulesEvaluationResult> => this.evaluate()
        )
        user.addPredicates(predicates)
        if (this.isSimpleUserPredicate(predicates)) {
          this.hasSimpleUserPredicate = true
        }

        return user
      }
    )
    return this
  }
  private isSimpleUserPredicate(predicates: Conditions): boolean {
    return (
      predicates.length === 1 &&
      isObject(predicates[0]) &&
      Object.keys(predicates[0]).length === 1 &&
      typeof predicates[0].status !== 'undefined'
    )
  }
  public basketTotalIs(...predicates: Conditions): this {
    this.basketTotal = import(
      /* webpackChunkName: "BasketTotal" */ './basketTotal'
    ).then((BasketTotalRule) => {
      const basketTotal = new BasketTotalRule.default(
        (): Promise<ITargetingRulesEvaluationResult> => this.evaluate()
      )
      basketTotal.addPredicates(predicates)
      return basketTotal
    })
    return this
  }
  public basketContains(...predicates: Conditions): this {
    this.basket = import(/* webpackChunkName: "Basket" */ './basket').then(
      (BasketRule) => {
        const basket = new BasketRule.default(
          (): Promise<ITargetingRulesEvaluationResult> => this.evaluate()
        )
        basket.addPredicates(predicates)
        return basket
      }
    )
    return this
  }
  public visits(...pagesUrls: string[]): this {
    const addExpectedJourney = (journey: Journey) =>
      journey.addExpectedJourney(pagesUrls)

    if (this.journey) {
      this.journey.then(addExpectedJourney, (e): void => {
        console.log(e)
      })
    } else {
      this.journey = importJourney().then((journey) => {
        addExpectedJourney(journey)
        return journey
      })
    }
    return this
  }
  public since(seconds: number): this {
    const addExpectedTime = (journey: Journey) =>
      journey.addExpectedTime(seconds, this.evaluate.bind(this))

    if (this.journey) {
      this.journey = this.journey.then((journey) => {
        addExpectedTime(journey)
        return journey
      })
    } else {
      this.journey = importJourney().then((journey) => {
        addExpectedTime(journey)
        return journey
      })
    }
    return this
  }
  public once(): this {
    this.onceRule = import(/* webpackChunkName: "Once" */ './once').then(
      (onceRule) => {
        const once = new onceRule.default(this.store.name)
        return once
      }
    )
    return this
  }
  public executeOnMatch(
    callback: (args: ITargetingRulesEvaluationResult) => void
  ): this {
    this.matchCallback = callback
    return this
  }
  public executeOnMismatch(
    callback: (args?: ITargetingRulesEvaluationResult) => void
  ): this {
    this.mismatchCallback = callback
    return this
  }
  public async unsubscribe(): Promise<void> {
    if (this.basketTotal) {
      ;(await this.basketTotal).off()
      delete this.basketTotal
    }
    if (this.user) {
      ;(await this.user).off()
      delete this.user
    }
    if (this.basket) {
      ;(await this.basket).off()
      delete this.basket
    }
    if (this.journey) {
      try {
        const journey: Journey = await this.journey
        journey.off()
        delete this.journey
      } catch (e) {
        throw new Error(e)
      }
    }
    if (this.xpMistmatches) {
      const xpMistmatches: XpMismatches = await this.xpMistmatches
      xpMistmatches.off()
      delete this.xpMistmatches
    }
  }

  private matchCallback: (args: ITargetingRulesEvaluationResult) => void = () => null

  private mismatchCallback: (args: ITargetingRulesEvaluationResult) => void = () => null

  public logDebug(message: string): void {
    debugMode &&
      console.log(
        'Casper Debug - XP Name : ' + this.store.name + ' - ' + message
      )
  }
}

export interface ITargetingRulesEvaluationResult {
  allValid?: boolean
  date?: Date | undefined
  unsubscribe: () => void
  user?: CustomerDetails
  totals?: IBasketTotals
  products?: IProduct[]
  pages?: IJourneyPage[]
  segments?: string[]
  persoSegments?: string[]
  urlParams?: IUnknownObject
  experiments?: ActiveExperiments
  standingOrders?: IStandingOrder[]
  subscriptions?: MergedSubscriptions[]
  xpName?: string
}

function importJourney() {
  return import(/* webpackChunkName: "Journey" */ './journey').then(
    (JourneyRule) => new JourneyRule.default()
  )
}
